import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ArticlesList from 'components/ArticlesList';
import { SEO, Layout, Hero } from 'components';
import NewsletterSection from 'components/NewsletterSection';
import {
  MainTitle,
  Subtitle,
  Pill,
  SingleInput,
  SingleInputButton,
  PillsNavBar,
  Container,
} from 'ui';
import { useQueryParam, StringParam } from 'use-query-params';
import { useDebounce } from 'react-use';
import { createClient, Provider, useQuery } from 'urql';
import { pickBy } from 'lodash';
import { Stack, Pills, ButtonReset } from '@tymate/margaret';
import { DATOCMS_SEARCH_TOKEN, DATOCMS_URL } from '../../constants';
import icSearch from 'images/ic-search.svg';

const ArticlesSearch = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
  paddingBottom: 2,
})`
  position: relative;
`;

const ArticlesSearchResultsCount = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  color: ${({ theme }) => theme.textOnDark};
  text-align: center;
  left: 0;
  width: 100%;
`;

const client = createClient({
  url: DATOCMS_URL,
  fetchOptions: () => ({
    headers: {
      Authorization: `Bearer ${DATOCMS_SEARCH_TOKEN}`,
    },
  }),
});

const GET_SEARCHED_ARTICLES = `
  query getSearchedArticles($search: String!, $tag: [ItemId]) {
    _allArticlesMeta(
      filter: {
        tags: { anyIn: $tag }
        OR: [
          { title: { matches: { pattern: $search } } }
          { content: { matches: { pattern: $search } } }
          { kicker: { matches: { pattern: $search } } }
        ]
      }
    ) {
      count
    }
    allArticles(
      filter: {
        tags: { anyIn: $tag }
        OR: [
          { title: { matches: { pattern: $search } } }
          { content: { matches: { pattern: $search } } }
          { kicker: { matches: { pattern: $search } } }
        ]
      }
    ) {
      id
      slug
      title
      kicker
      _firstPublishedAt
      author {
        id
        name
        description
        picture {
          responsiveImage(imgixParams: { ar: "1", fit: crop }) {
            src
          }
        }
      }
      cover {
        blurUpThumb
        url
      }
      tags {
        id
      }
    }
  }
`;

const Articles = ({
  data: { datoCmsBlogPage, allDatoCmsArticle, allDatoCmsTag },
  pageContext,
}) => {
  const [q, setQ] = useQueryParam('q', StringParam);
  const [tag, setTag] = useQueryParam('tag', StringParam);
  const [search, setSearch] = useState(q || '');

  const isSearching = Boolean(q) || Boolean(tag);
  const [{ data }] = useQuery({
    query: GET_SEARCHED_ARTICLES,
    pause: !isSearching,
    variables: pickBy({ search: q || ' ', tag }),
  });

  const articles = isSearching
    ? data?.allArticles || []
    : (allDatoCmsArticle?.edges || []).map(({ node }) => ({ ...node }));
  // Flattening nodes is necessary because search results are not included in nodes
  const resultsCount = data?._allArticlesMeta?.count;

  useDebounce(
    () => {
      setQ(Boolean(search) ? search : undefined);
    },
    500,
    [search],
  );

  return (
    <>
      <SEO
        title={datoCmsBlogPage?.metaTags?.title}
        description={datoCmsBlogPage?.metaTags?.description}
      />

      <Layout
        hero={
          <Hero cover={datoCmsBlogPage?.cover} variant="bleeding">
            <Stack direction="column" alignX="center" gutterSize={2}>
              <Stack direction="column" alignX="center" gutterSize={1}>
                <MainTitle as="h1" variant="light">
                  {datoCmsBlogPage?.title}
                </MainTitle>
                {Boolean(datoCmsBlogPage?.description) && (
                  <Subtitle>{datoCmsBlogPage?.description}</Subtitle>
                )}
              </Stack>

              <Stack direction="column" alignX="center">
                <ArticlesSearch>
                  <div css="position: relative; margin-left: auto; margin-right: auto;">
                    <SingleInput
                      id="search"
                      placeholder="Rechercher un article"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                      variant="search"
                    />
                    <SingleInputButton
                      as="label"
                      htmlFor="search"
                      variant="search"
                    >
                      <img src={icSearch} alt="Rechercher" />
                    </SingleInputButton>
                  </div>

                  {Boolean(data?.allArticles) && (Boolean(q) || Boolean(tag)) && (
                    <ArticlesSearchResultsCount>
                      {resultsCount} résultat{resultsCount > 1 ? 's' : ''}{' '}
                      contenant votre recherche
                    </ArticlesSearchResultsCount>
                  )}
                </ArticlesSearch>

                <Container>
                  <PillsNavBar>
                    <Pills>
                      <Pill>
                        <ButtonReset
                          activeClassName="active"
                          type="button"
                          onClick={() => {
                            setTag(undefined);
                          }}
                          className={!Boolean(tag) ? 'active' : null}
                        >
                          Tous
                        </ButtonReset>
                      </Pill>

                      {(allDatoCmsTag?.edges || []).map(({ node }) => (
                        <Pill key={node.originalId}>
                          <ButtonReset
                            activeClassName="active"
                            type="button"
                            onClick={e => {
                              e.preventDefault();
                              setTag(node.originalId);
                            }}
                            className={
                              node.originalId === tag ? 'active' : null
                            }
                          >
                            {node?.name}
                          </ButtonReset>
                        </Pill>
                      ))}
                    </Pills>
                  </PillsNavBar>
                </Container>
              </Stack>
            </Stack>
          </Hero>
        }
      >
        <ArticlesList
          articles={articles}
          pageContext={pageContext}
          kind={Boolean(data?.allArticles) ? 'search' : null}
        />

        <NewsletterSection />
      </Layout>
    </>
  );
};

const ArticlesPage = props => (
  <Provider value={client}>
    <Articles {...props} />
  </Provider>
);

export const query = graphql`
  query {
    datoCmsBlogPage {
      metaTags {
        title
        description
      }
      title
      description
      cover {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.77)
      }
    }
    allDatoCmsTag {
      edges {
        node {
          id
          slug
          name
          originalId
        }
      }
    }
    allDatoCmsArticle {
      edges {
        node {
          id
          slug
          title
          kicker
          meta {
            firstPublishedAt
          }
          author {
            id
            name
            description
            picture {
              gatsbyImageData(aspectRatio: 1, width: 40)
            }
          }
          cover {
            url
            gatsbyImageData(aspectRatio: 2)
          }
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export default ArticlesPage;
