import React from 'react';
import styled from 'styled-components';
import { Legend } from '../ui';
import { Box } from '@tymate/margaret';
import { formatDate } from 'utils';
import Dotdotdot from 'react-dotdotdot';

export const Card = styled(Box)`
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.12);
  text-decoration: none;
  color: inherit;
`;

const CardTitle = styled(Box).attrs({ as: Dotdotdot, clamp: 2 })`
  ${({ theme }) => theme.fontStyles.bodyLarge}
  font-weight: 600;
`;

const CardDescription = styled(Box).attrs({ as: Dotdotdot, clamp: 4 })`
  ${({ theme }) => theme.fontStyles.bodySmall}
  flex: 1;
  color: ${({ theme }) => theme.textLight};
`;

const CardTimestamp = ({ timestamp }) => (
  <Legend>{formatDate(timestamp, 'd MMMM yyyy')}</Legend>
);

const CardFooter = styled(Box).attrs({ padding: 1 })`
  ${({ variant }) =>
    variant === 'inline'
      ? `
        padding-top: 0 !important;
      `
      : `
        border-top: 1px solid ${({ theme }) => theme.separator};
      `}
`;

Card.Title = CardTitle;
Card.Footer = CardFooter;
Card.Timestamp = CardTimestamp;
Card.Description = CardDescription;

export default Card;
