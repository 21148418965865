import React from 'react';
import styled, { css } from 'styled-components';
import Card from 'components/Card';
import AvatarAndName from 'components/AvatarAndName';
import { Box, Stack } from '@tymate/margaret';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Thumbnail = styled(GatsbyImage)``;

const ArticleCardBase = styled(Card).attrs({ as: Link })`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ variant }) =>
    variant === 'inline' &&
    css`
      flex-direction: row;
    `}
`;

const ArticleCardContent = styled(Box).attrs({
  padding: 1,
  size: 'full',
})`
  flex: 1;

  ${({ variant }) =>
    variant === 'inline' &&
    `
      padding-bottom: 0!important;
    `}
`;
const SearchThumbnail = styled.div`
  padding-bottom: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: norepeat;
`;

const ArticleCard = ({ article, variant, kind }) => {
  const {
    title,
    author,
    kicker,
    cover,
    slug,
    meta,
    _firstPublishedAt,
  } = article;

  return (
    <ArticleCardBase to={`/actualites/${slug}`} variant={variant}>
      {kind !== 'search' && <Thumbnail image={cover?.gatsbyImageData} />}
      {kind === 'search' && (
        <SearchThumbnail
          css={`
            background-image: url(${cover?.url});
          `}
        />
      )}

      <Stack style={{ flex: 1 }} direction="column" alignX="stretch">
        <ArticleCardContent variant={variant}>
          <Stack size="full" alignX="space-between" alignY="center">
            <Card.Timestamp
              timestamp={meta?.firstPublishedAt || _firstPublishedAt}
            />
          </Stack>

          <Card.Title paddingVertical={0.25}>{title}</Card.Title>

          {Boolean(kicker) && variant !== 'inline' && (
            <ArticleCardBase.Description>{kicker}</ArticleCardBase.Description>
          )}
        </ArticleCardContent>

        <ArticleCardBase.Footer variant={variant}>
          <AvatarAndName
            picture={author?.picture}
            name={author?.name}
            description={author?.description}
            size="small"
          />
        </ArticleCardBase.Footer>
      </Stack>
    </ArticleCardBase>
  );
};

export default ArticleCard;
