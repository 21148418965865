import React from 'react';
import styled, { css } from 'styled-components';
import { Stack } from '@tymate/margaret';
import { GatsbyImage } from 'gatsby-plugin-image';

const Avatar = styled(GatsbyImage)`
  width: 40px;
  height: 40px;
  border-radius: 100%;

  ${({ variant }) =>
    variant === 'inline' &&
    `
      flex-wrap: wrap;
    `}

  ${({ size }) =>
    size === 'small' &&
    `
      width: 24px;
      height: 24px;
    `}
`;

const Name = styled.div`
  font-weight: 700;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.textLight};
`;

const Content = styled(Stack).attrs(({ variant }) => ({
  direction: variant === 'inline' ? 'row' : 'column',
  gutterSize: variant === 'inline' ? 0.25 : 0,
}))`
  ${({ theme }) => theme.fontStyles.bodySmall}

  ${({ variant }) =>
    variant === 'inline' &&
    css`
      > ${Description}:before {
        content: '– ';
      }
    `}
`;

const AvatarAndName = ({ picture, name, description, variant, size }) => (
  <Stack gutterSize={0.5} alignY="center">
    <Stack>
      <Avatar image={picture?.gatsbyImageData} variant={variant} size={size} />
    </Stack>
    <Content variant={variant}>
      <Name>{name}</Name>
      <Description>{description}</Description>
    </Content>
  </Stack>
);

export default AvatarAndName;
